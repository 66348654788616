
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { UnorderedList } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * CMSAdminComponentUnorderedListList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-unordered-list-form-dialog': () => import(
            './CMSAdminComponentUnorderedListFormDialog.vue'
        )
    }
})
export default class CMSAdminComponentUnorderedListList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all lists
    @Prop({ type: Array as () => Array<UnorderedList> }) readonly lists: Array<UnorderedList> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited list
    private editedListItem: UnorderedList = {
        id: -1,
        header: 'N/A',
        items: []
    };

    // Default list item values
    private defaultListItem: UnorderedList = {
        id: -1,
        header: 'N/A',
        items: []
    }

    // The index of the edited list (-1 = new list)
    private editedListIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.header').toString(),
                value: 'header'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the ListFormDialog to create a new list
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addList() {
        // get max id
        const id = (this.lists && this.lists.length >= 1) ? this.lists[this.lists.length -1].id +1 : 0;

        // FIXME spread is copying reference?
        this.editedListItem = CloneHelper.deepCloneObject({...this.defaultListItem, id: id });
        this.editedListIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the ListFormDialog to edit the given list element
     * 
     * @param listItem list item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editList(listItem: UnorderedList) {
        if (this.lists) {
            this.editedListItem = CloneHelper.deepCloneObject(listItem);
            this.editedListIndex = this.lists.indexOf(listItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given list
     * 
     * @param listItem list item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteList(listItem: UnorderedList) {
        if (this.lists) {
            this.editedListItem = CloneHelper.deepCloneObject(listItem);
            this.editedListIndex = this.lists.indexOf(listItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedList(formDialogMode: string, listItemIndex: number, listItem: UnorderedList) {
        if (this.lists) {
            if (formDialogMode == 'create') {
                this.lists.push(listItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.lists[listItemIndex], listItem);
            }
            else if (formDialogMode == 'delete') {
                this.lists.splice(listItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
